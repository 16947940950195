import React from "react";
import SEO from "../components/seo";

function QuemSomosPage() {
    return (
        <>
            <SEO title="Área de Competência" />
            <section className="quem-somos-banner"></section>
            <section className="py-8 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto text-azul_base">
                    <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl">Área de Competência</h1>
                    <p className="w-full text-base text-preto leading-none lg:text-lg lg:leading-tight">
                        A área de competência da circunscrição do Cartório Saúde é utilizada apenas para o serviços de habilitação de casamento, registro de nascimento e registro de óbito. Demais serviços (reconhecimento de firmas, autenticações, procurações públicas, apostilamento, segunda via de certidões e demais procedimentos de registro civil) podem ser feito livremente no cartório de sua escolha.
                    </p>
                    <div className="mt-8 flex flex-col lg:flex-row lg:justify-start">
                        <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1r_6gIil_KqBBNucl_aWK6KLIHS6icgRi" width="100%" height="480"></iframe>
                    </div>
                </div>
            </section>
        </>
    );
}

export default QuemSomosPage;
